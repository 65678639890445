import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import applicationEnv from '../../applicationEnv';

const HealthCheckBanner = () => {
    const [isSsoHealthy, setIsSsoHealthy] = useState(true);

    const performSsoHealthCheck = async () => {
        try {
            const response = await fetch(
                `${applicationEnv.ssoUrl}/lumina-health`,
                { method: 'GET' }
            );

            setIsSsoHealthy(response.status === 200);
        } catch (error) {
            console.error(error);
            setIsSsoHealthy(false);
        }
    };

    useEffect(() => {
        (async () => {
            await performSsoHealthCheck();
        })();
    }, []);

    return !isSsoHealthy
        ? <div className="health-check-banner">
            <FormattedMessage id="HEALTH_CHECK_BANNER.UNHEALTHY_SERVICE_TEXT" defaultMessage="We are currently experiencing issues with our service, please reload the page or come back later" />
        </div>
        : <></>;
};

export default HealthCheckBanner;