import { lazy } from 'react';

const AccountDetailsPage = lazy(() => import('./Components/Pages/AccountDetailsPage'));
const AdminPage = lazy(() => import('./Components/Pages/AdminPage'));
const Auth0User = lazy(() => import('./Components/Pages/AdminPage/Auth0User'));
const Auth0Users = lazy(() => import('./Components/Pages/AdminPage/Auth0Users'));
const DelegationPage = lazy(() => import('./Components/Pages/DelegationPage'));
const DelegateRegistrationPage = lazy(() => import('./Components/Pages/DelegateRegistrationPage'));
const DelegateHistoryPage = lazy(() => import('./Components/Pages/DelegateHistoryPage'));
const AmendDelegationPage = lazy(() => import('./Components/Pages/AmendDelegationPage'));
const KeycloakUser = lazy(() => import('./Components/Pages/AdminPage/KeycloakUser'));
const KeycloakUsers = lazy(() => import('./Components/Pages/AdminPage/KeycloakUsers'));
const ParticipantProfile = lazy(() => import('./Components/Pages/AdminPage/ParticipantProfile'));
const PartnerProfile = lazy(() => import('./Components/Pages/AdminPage/PartnerProfile'));
const PractitionerProfile = lazy(() => import('./Components/Pages/AdminPage/PractitionerProfile'));
const ProfileImpersonation = lazy(() => import('./Components/Pages/AdminPage/ProfileImpersonation'));
const ProfileSearch = lazy(() => import('./Components/Pages/AdminPage/ProfileSearch'));
const AuthFrame = lazy(() => import('./Components/Pages/AuthFrame'));
const EmailVerificationPage = lazy(() => import('./Components/Pages/EmailVerificationPage'));
const ForgotPasswordPage = lazy(() => import('./Components/Pages/ForgotPasswordPage'));
const HealthCheck = lazy(() => import('./Components/Pages/HealthCheck'));
const LoginPage = lazy(() => import('./Components/Pages/LoginPage'));
const NotFoundPage = lazy(() => import('./Components/Pages/NotFoundPage'));
const PortalPage = lazy(() => import('./Components/Pages/PortalPage'));
const PortraitsPage = lazy(() => import('./Components/Pages/PortraitsPage'));
const ProfilePage = lazy(() => import('./Components/Pages/ProfilePage'));
const RegistrationPage = lazy(() => import('./Components/Pages/RegistrationPage'));
const ResetPasswordPage = lazy(() => import('./Components/Pages/ResetPasswordPage'));
const SecurityPage = lazy(() => import('./Components/Pages/SecurityPage'));
const ServicesPage = lazy(() => import('./Components/Pages/ServicesPage'));
const UnblockAccountPage = lazy(() => import('./Components/Pages/UnblockAccountPage'));
const ForgotUsernamePage = lazy(() => import('./Components/Pages/ForgotUsernamePage'));
const MfaIframe = lazy(() => import('./Components/Pages/MfaIframe'));

const pages: Page[] = [
    {
        name: 'auth-login-frame',
        title: { key: 'AUTH_LOGIN_FRAME', name: 'Auth Login Frame' },
        path: '/auth-frame',
        exact: true,
        element: AuthFrame,
    },
    {
        name: 'project-registration',
        title: { key: 'PROJECT_REGISTRATION', name: 'Project Registration' },
        path: '/register/project/:userType/:urlType/:projectRegistrationId',
        exact: true,
        element: RegistrationPage,
    },
    {
        name: 'forgot-password',
        title: { key: 'FORGOT_PASSWORD', name: 'Forgot Password' },
        path: '/password/reset',
        requiresLogout: true,
        exact: true,
        element: ForgotPasswordPage,
    },
    {
        name: 'reset-password',
        title: { key: 'RESET_PASSWORD', name: 'Reset Password' },
        path: '/password/reset/:keycloakId/:passwordResetToken',
        requiresLogout: true,
        exact: true,
        element: ResetPasswordPage,
    },
    {
        name: 'forgot-username',
        title: { key: 'FORGOT_USERNAME', name: 'Forgot Username' },
        path: '/username/forgot',
        requiresLogout: true,
        exact: true,
        element: ForgotUsernamePage,
    },
    {
        name: 'login',
        title: { key: 'LOGIN', name: 'Login' },
        path: '/login',
        requiresLogout: true,
        exact: true,
        element: LoginPage,
    },
    {
        name: 'email-verification',
        title: { key: 'VERIFY_EMAIL', name: 'Verify Your Email' },
        path: '/verify/email/:keycloakId/:verificationToken',
        requiresLogin: true,
        exact: true,
        element: EmailVerificationPage,
    },
    {
        name: 'portal',
        title: { key: 'PORTAL', name: 'Portal' },
        path: '/portal/dashboard',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: PortalPage,
    },
    {
        name: 'portraits',
        title: { key: 'PORTRAITS', name: 'Portraits' },
        path: '/portal/portraits',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: PortraitsPage,
    },
    {
        name: 'services',
        title: { key: 'SERVICES', name: 'Services' },
        path: '/portal/services',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: ServicesPage,
    },
    {
        name: 'profile',
        title: { key: 'PROFILE', name: 'Profile' },
        path: '/portal/profile',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: ProfilePage,
    },
    {
        name: 'account-security',
        title: { key: 'ACCOUNT_SECURITY', name: 'Account Security' },
        path: '/portal/security',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: SecurityPage,
    },
    {
        name: 'account-security-login',
        title: { key: 'ACCOUNT_SECURITY', name: 'Account Security' },
        path: '/portal/security/login-details',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: AccountDetailsPage,
    },
    {
        name: 'account-security-delegation',
        title: { key: 'ACCOUNT_SECURITY', name: 'Users and Permissions' },
        path: '/portal/security/delegation',
        requiresLogin: true,
        exact: true,
        sidebar: true,
        element: DelegationPage,
    },
    {
        name: 'partner-admin-dashboard',
        title: { key: 'PARTNER_ADMIN', name: 'Partner Admin' },
        path: '/partner/admin',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: AdminPage,
        props: { userType: 'partner' },
    },
    {
        name: 'partner-admin-search-practitioners',
        title: { key: 'PARTNER_ADMIN', name: 'Partner Admin' },
        path: '/partner/admin/practitioners',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: ProfileSearch,
        props: { userType: 'partner', profileType: 'practitioner' },
    },
    {
        name: 'partner-admin-practitioner',
        title: { key: 'PARTNER_ADMIN', name: 'Partner Admin' },
        path: '/partner/admin/practitioner/:practitionerId',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: PractitionerProfile,
        props: { userType: 'partner' },
    },
    {
        name: 'partner-admin-search-participants',
        title: { key: 'PARTNER_ADMIN', name: 'Partner Admin' },
        path: '/partner/admin/participants',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: ProfileSearch,
        props: { userType: 'partner', profileType: 'participant' },
    },
    {
        name: 'partner-admin-participant',
        title: { key: 'PARTNER_ADMIN', name: 'Partner Admin' },
        path: '/partner/admin/participant/:participantId',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: ParticipantProfile,
        props: { userType: 'partner' },
    },
    {
        name: 'admin-dashboard',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: AdminPage,
        props: { userType: 'staff' },
    },
    {
        name: 'admin-keycloak-user',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/user/keycloak/:keycloakUserId',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: KeycloakUser,
    },
    {
        name: 'admin-search-keycloak-users',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/users/keycloak',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: KeycloakUsers,
    },
    {
        name: 'admin-auth0-user',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/user/auth0/:auth0UserId',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: Auth0User,
    },
    {
        name: 'admin-search-auth0-users',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/users/auth0',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: Auth0Users,
    },
    {
        name: 'admin-search-partners',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/partners',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: ProfileSearch,
        props: { userType: 'staff', profileType: 'partner' },
    },
    {
        name: 'admin-partner',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/partner/:partnerId',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: PartnerProfile,
    },
    {
        name: 'admin-search-practitioners',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/practitioners',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: ProfileSearch,
        props: { userType: 'staff', profileType: 'practitioner' },
    },
    {
        name: 'admin-practitioner',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/practitioner/:practitionerId',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: PractitionerProfile,
        props: { userType: 'staff' },
    },
    {
        name: 'admin-search-participants',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/participants',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: ProfileSearch,
        props: { userType: 'staff', profileType: 'participant' },
    },
    {
        name: 'admin-participant',
        title: { key: 'ADMIN', name: 'Admin' },
        path: '/admin/participant/:participantId',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: ParticipantProfile,
        props: { userType: 'staff' },
    },
    {
        name: 'profile-impersonation',
        title: { key: 'PROFILE_IMPERSONATION_DASHBOARD', name: 'Profile Impersonation Dashboard' },
        path: '/admin/profile-impersonation',
        requiresLogin: true,
        requiresAdmin: true,
        exact: true,
        sidebar: true,
        element: ProfileImpersonation,
        props: { userType: 'staff' },
    },
    {
        name: 'profile-impersonation',
        title: { key: 'PROFILE_IMPERSONATION_DASHBOARD', name: 'Profile Impersonation Dashboard' },
        path: '/partner/admin/profile-impersonation',
        requiresLogin: true,
        requiresPartner: true,
        exact: true,
        sidebar: true,
        element: ProfileImpersonation,
        props: { userType: 'partner' },
    },
    {
        name: 'delegate-registration',
        title: { key: 'DELEGATE_REGISTRATION', name: 'Delegate Registration' },
        path: '/register/delegate/:profileType/:invitationKey',
        exact: true,
        element: DelegateRegistrationPage,
    },
    {
        name: 'delegate-amend-permissions',
        title: { key: 'DELEGATE_AMEND_PERMISSION', name: 'Amend Delegate Permissions' },
        path: '/portal/security/delegation/edit/:profileType/:invitationId',
        exact: true,
        requiresLogin: true,
        sidebar: true,
        element: AmendDelegationPage,
    },
    {
        name: 'delegate-permissions-history',
        title: { key: 'DELEGATE_PERMISSION_HISTORY', name: 'Delegate Permission History' },
        path: '/portal/security/delegation/history/:profileType/:invitationId',
        exact: true,
        requiresLogin: true,
        sidebar: true,
        element: DelegateHistoryPage,
    },
    {
        name: 'unblock-account',
        title: { key: 'UNBLOCK_ACCOUNT', name: 'Unblock Account' },
        path: '/unblock/:unblockToken',
        exact: true,
        element: UnblockAccountPage,
    },
    {
        name: 'health-check',
        title: { key: 'HEALTH_CHECK', name: 'Application Health check' },
        path: '/health',
        exact: true,
        sidebar: false,
        element: HealthCheck,
    },
    {
        name: 'mfa-iframe',
        title: { key: 'MFA_FORM.FORM_TITLE', name: 'Two-Factor Authentication' },
        path: '/login/mfa/:userId',
        exact: true,
        element: MfaIframe,
    },
    {
        name: 'not-found',
        title: { key: 'NOT_FOUND', name: '404 Not Found' },
        path: '*',
        exact: false,
        element: NotFoundPage,
    },
];

export default pages;