import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircle,
    faSearch,
    faKey,
    faUser,
    faUserTie,
    faBriefcase,
    faArrowLeft,
    faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const AdminSidebar = ({ isStaff }: { isStaff: boolean; }) => {
    const { pathname } = useLocation();
    const adminPath = isStaff ? '/admin' : '/partner/admin';

    return (
        <ul>
            <NavLink to='/portal' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
                <li>
                    <span className="fa-layers fa-fw"><FontAwesomeIcon icon={faArrowLeft} /></span>
                    <FormattedMessage id="ADMIN_SIDEBAR.TO_PORTAL" defaultMessage="Back to Portal" />
                </li>
            </NavLink>
            <NavLink end to={`${adminPath}`} className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
                <li>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                        <FontAwesomeIcon icon={faSearch} className='icon' />
                    </span>
                    <FormattedMessage id="ADMIN_SIDEBAR.QUICK_SEARCH" defaultMessage="Quick Search" />
                </li>
            </NavLink>
            { isStaff
                && <NavLink
                    to='/admin/users/keycloak'
                    className={
                        ({ isActive }) => isActive || pathname.includes('/admin/user/keycloak')
                            ? 'sidebar-active'
                            : ''
                    }
                >
                    <li>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                            <FontAwesomeIcon icon={faKey} className='icon' />
                        </span>
                        <FormattedMessage id="ADMIN_SIDEBAR.LUMINA_ACCOUNTS" defaultMessage="Lumina Accounts" />{' '}(Keycloak)
                    </li>
                </NavLink> }
            { isStaff
                && <NavLink
                    to='/admin/users/auth0'
                    className={
                        ({ isActive }) => isActive || pathname.includes('/admin/user/auth0')
                            ? 'sidebar-active'
                            : ''
                    }
                >
                    <li>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                            <FontAwesomeIcon icon={faKey} className='icon' />
                        </span>
                        <FormattedMessage id="ADMIN_SIDEBAR.LUMINA_ACCOUNTS" defaultMessage="Lumina Accounts" />{' '}(Auth0)
                    </li>
                </NavLink> }
            { isStaff
                && <NavLink
                    to='/admin/partners'
                    className={
                        ({ isActive }) => isActive || pathname.includes('/admin/partner/')
                            ? 'sidebar-active'
                            : ''
                    }
                >
                    <li>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                            <FontAwesomeIcon icon={faBriefcase} className='icon' />
                        </span>
                        <FormattedMessage id="ADMIN_SIDEBAR.PARTNER_PROFILES" defaultMessage="Partner Profiles" />
                    </li>
                </NavLink> }
            <NavLink
                to={`${adminPath}/practitioners`}
                className={
                    ({ isActive }) => isActive || pathname.includes('/admin/practitioner/')
                        ? 'sidebar-active'
                        : ''
                }
            >
                <li>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                        <FontAwesomeIcon icon={faUserTie} className='icon' />
                    </span>
                    <FormattedMessage id="ADMIN_SIDEBAR.PRACTITIONER_PROFILES" defaultMessage="Practitioner Profiles" />
                </li>
            </NavLink>
            <NavLink
                to={`${adminPath}/participants`}
                className={
                    ({ isActive }) => isActive || pathname.includes('/admin/participant/')
                        ? 'sidebar-active'
                        : ''
                }
            >
                <li>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                        <FontAwesomeIcon icon={faUser} className='icon' />
                    </span>
                    <FormattedMessage id="ADMIN_SIDEBAR.PARTICIPANT_PROFILES" defaultMessage="Participant Profiles" />
                </li>
            </NavLink>
            <NavLink
                to={`${adminPath}/profile-impersonation`}
                className={({ isActive }) => isActive ? 'sidebar-active' : '' }
            >
                <li>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                        <FontAwesomeIcon icon={faUserSecret} className='icon' />
                    </span>
                    <FormattedMessage id="ADMIN_SIDEBAR.IMPERSONATION" defaultMessage="Impersonation" />
                </li>
            </NavLink>
        </ul>
    );
};

export default AdminSidebar;