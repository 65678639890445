const locales = {
    'en-GB': {
        localName: 'English',
        englishName: 'English',
    },
};

const isLocaleValid = (locale: string) => Object.keys(locales).includes(locale);

const loadMessages = (locale: string) => {
    const translationFilename = isLocaleValid(locale) ? locale : 'en-GB';

    // eslint-disable-next-line global-require
    return require(`../lang/${translationFilename}.json`);
};

const selectValidLocale = (locale: string | null) => {
    const selectedLocale = (locale === null || locale === '') ? navigator.language : locale;

    return isLocaleValid(selectedLocale) ? selectedLocale : 'en-GB';
};

// Flatten keys in translation json, react-intl doesn't support nested keys
// https://stackoverflow.com/questions/45783677/react-intl-accessing-nested-messages
const flattenTranslationKeys = ((messages: any, prefix = '') => {
    if (messages === null) return {};

    return Object.keys(messages).reduce((flatMessages, key) => {
        const value = messages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        const message = typeof value === 'string'
            ? { [prefixedKey]: value }
            : flattenTranslationKeys(value, prefixedKey);

        Object.assign(flatMessages, message);

        return flatMessages;
    }, {});
});

// We don't want to supply default messages for each string
// react-intl/formatjs doesn't handle it by default
// https://github.com/formatjs/formatjs/issues/557
const loadLocaleMessages = (locale: string) => {
    const validLocale = selectValidLocale(locale);
    const defaultMessages = flattenTranslationKeys(loadMessages('en-GB'));

    return validLocale === 'en-GB' // en-GB is our default, complete locale
        ? defaultMessages
        : { ...defaultMessages, ...flattenTranslationKeys(loadMessages(validLocale)) };
};

export {
    locales, isLocaleValid, selectValidLocale, loadLocaleMessages,
};
