import { gql } from '@apollo/client';

const GET_LOCALE = gql`
    query GetLocale {
        appLocale @client
    }
`;

const GET_LUMINA_ONLINE_LANGUAGES = gql`
    query Languages {
        languages {
            id name nativeName code
        }
    }
`;

export { GET_LOCALE, GET_LUMINA_ONLINE_LANGUAGES };
