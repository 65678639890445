import { useRef } from 'react';
import useOutsideClick from '../../Hooks/useOutsideClick';

/**
 * Wrapper component will run callback function when clicking outside it
 */
const DetectOutsideClick = (props: { children?: any; callback: () => any }) => {
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, props.callback);

    return <div ref={wrapperRef} className="detect-outside-click">{props.children}</div>;
};

export default DetectOutsideClick;
