import { Navigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { currentPage } from '../../graphql/client';
import { decodeJwt, isHasStaffProfile, isHasPartnerProfile } from '../../helpers/checkJwt';

const DocumentTitle = ({ transKey, name }: { transKey: string; name: string; }) => (
    <FormattedMessage id={transKey} defaultMessage={name}>
        {(...message) => {
            const title = `${message} - Lumina Learning`;

            if (document.title !== title) {
                document.title = title;
            }

            return null;
        }}
    </FormattedMessage>
);

const PageHeader = ({
    isPartnerPage, isAdminPage, pageTitle, pageName,
}: PageHeaderProps) => {
    currentPage(pageName); // set page name
    const { key, name } = pageTitle;
    const accessToken = localStorage.getItem('LUMINA_ACCESS_TOKEN');

    let isAllowedToAccessAdminPage = true;
    let isAllowedToAccessPartnerPage = true;
    if (accessToken) {
        const decoded = decodeJwt(accessToken);
        if (decoded) {
            isAllowedToAccessAdminPage = isHasStaffProfile(decoded);
            isAllowedToAccessPartnerPage = isHasPartnerProfile(decoded);
        }
    }

    return (
        (isAdminPage && isAllowedToAccessAdminPage)
        || (isPartnerPage && isAllowedToAccessPartnerPage)
        || (!isAdminPage && !isPartnerPage)
    ) ? <DocumentTitle transKey={`PAGE_TITLES.${key}`} name={name} /> : <Navigate to="/portal/dashboard" replace />;
};

export default PageHeader;
