import { ApolloClient } from '@apollo/client';
import applicationEnv from '../applicationEnv';
import { AUTH_REFRESH, IS_LOGGED_IN, AUTH_LOGOUT } from '../graphql/authQuery';
import getCookie from '../helpers/cookie';
import { decodeJwt, isTokenExpired, isTokenIssValid } from '../helpers/checkJwt';

const keycloakUserInfo = async (accessToken: string) => {
    const options: any = {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const response = await fetch(
        `${applicationEnv.ssoUrl}/api/keycloak/me`,
        options
    );

    if (response && response.ok) {
        return response.json();
    }

    throw new Error('request_error');
};

const clearAuthenticationFromStorageAndCookies = (removeCookie: any) => {
    localStorage.removeItem('LUMINA_ACCESS_TOKEN');
    localStorage.removeItem('LUMINA_KEYCLOAK_ID');

    removeCookie('luminaAccessToken');
    removeCookie('luminaRefreshToken');
};

const setTokenCookies = (
    setCookie: any,
    accessToken: string,
    refreshToken: string,
    userId: string
) => {
    const accessTokenExpires = new Date(decodeJwt(accessToken).exp * 1000);
    const cookieExpires = new Date((new Date()).setDate(accessTokenExpires.getDate() + 7));

    setCookie('luminaAccessToken', accessToken, { path: '/', accessTokenExpires });
    setCookie('luminaRefreshToken', refreshToken, { path: '/', cookieExpires });

    localStorage.setItem('LUMINA_ACCESS_TOKEN', accessToken);
    localStorage.setItem('LUMINA_KEYCLOAK_ID', userId);
};

const logoutLuminaOnline = async () => {
    const options: any = {
        method: 'POST',
        credentials: 'include',
    };

    // This will always remove any lumina online session except in the case of network or request error
    // If response message is false, its simpy because there are no existing session to remove
    const response = await fetch(`${applicationEnv.loUrl}/ssoservicesv2/logout`, options);

    if (response && response.ok) {
        return response.json();
    }

    throw new Error('request_error');
};

const initLogout = async (
    client: ApolloClient<Object>,
    removeCookie: any
): Promise<Boolean> => {
    let isLoggedOut = false;
    try {
        const { data } = await client.mutate({ mutation: AUTH_LOGOUT });

        await logoutLuminaOnline();

        isLoggedOut = data.authLogout;

        // eslint-disable-next-line no-restricted-globals
        parent.postMessage(JSON.stringify({ method: 'logout', data: true }), '*');
    } catch (e) {
        console.error(e);
    }

    // Clean local session anyway even if logout failed
    clearAuthenticationFromStorageAndCookies(removeCookie);

    return isLoggedOut;
};

const initAuthentication = async (
    accessToken: string,
    refreshToken: string,
    userId: string,
    setCookie: any,
    removeCookie: any
) => {
    try {
        const userInfo = await keycloakUserInfo(accessToken);

        // Double check exp and iss after confirming its authorised from keycloak
        if (userInfo) {
            const isTokenNotExpired = !isTokenExpired(accessToken);
            const isIssValid = isTokenIssValid(accessToken);

            if (!isTokenNotExpired) {
                console.error('Token is expired');
            }

            if (!isIssValid) {
                console.error('Invalid token issuer');
            }

            if (isTokenNotExpired && isIssValid) {
                setTokenCookies(setCookie, accessToken, refreshToken, userId);
                return true;
            }
        }
    } catch (error) {
        console.error(error);
    }

    clearAuthenticationFromStorageAndCookies(removeCookie);
    return false;
};

const refreshAccessToken = async (
    setCookie: any,
    removeCookie: any,
    client: ApolloClient<Object>,
    forceRefresh?: boolean
) => {
    const accessToken = getCookie('luminaAccessToken');
    const refreshToken = getCookie('luminaRefreshToken');
    const userId = localStorage.getItem('LUMINA_KEYCLOAK_ID');

    if (accessToken && refreshToken) {
        const decodedToken = decodeJwt(accessToken);

        if (decodedToken && decodedToken.sub === userId) {
            // Check if current token is expired, logout the user
            if (isTokenExpired(accessToken)) {
                await initLogout(client, removeCookie);
            } else if (
                // decodedToken.exp is unix timestamp which is in s, we convert to Javascript Date class which is in ms
                // Refresh token if its exp is less than 30 minutes (1000ms/1s * 60 * 30)
                (1000 * decodedToken.exp) - (1000 * 60 * 30) < (new Date().getTime())
                || forceRefresh
            ) {
                const { data } = await client.mutate({
                    mutation: AUTH_REFRESH,
                    variables: { refreshToken },
                    fetchPolicy: 'no-cache',
                });
                const responseData = data.authRefresh;

                if (responseData && typeof responseData.error === 'undefined') {
                    // Error,
                    // we leave the access token as is and let it expire instead of
                    // logged them out since its still valid
                    setTokenCookies(
                        setCookie,
                        responseData.accessToken,
                        responseData.refreshToken,
                        responseData.keycloakUserId
                    );
                }
            }
        }
    }
};

const initAuthenticationFromStorage = async (
    setCookie: any,
    removeCookie: any,
    client: ApolloClient<Object>
) => {
    const accessToken = getCookie('luminaAccessToken');
    const refreshToken = getCookie('luminaRefreshToken');
    const userId = localStorage.getItem('LUMINA_KEYCLOAK_ID');

    if (accessToken && refreshToken && userId) {
        const decodedToken = decodeJwt(accessToken);
        if (decodedToken && decodedToken.sub === userId) {
            try {
                const isAuthenticated = await initAuthentication(
                    accessToken,
                    refreshToken,
                    userId,
                    setCookie,
                    removeCookie
                );
                if (isAuthenticated) {
                    await refreshAccessToken(setCookie, removeCookie, client, true);

                    return true;
                }
            } catch (error) {
                console.error(error);
            }

            // Trigger logout
            await initLogout(client, removeCookie);
        }
    } else {
        // Make sure coolie and storage is cleared if any component is not present
        clearAuthenticationFromStorageAndCookies(removeCookie);
    }

    // Update logged in status in application cache
    client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: !!localStorage.getItem('LUMINA_ACCESS_TOKEN') } });

    return false;
};

const loginLuminaOnline = async (
    luminaOnlineUserType: string,
    luminaOnlineUserId: number
) => {
    const token = localStorage.getItem('LUMINA_ACCESS_TOKEN');
    const options: any = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({
            lumina_user_type: luminaOnlineUserType,
            lumina_user_id: luminaOnlineUserId,
        }),
    };

    const response = await fetch(
        `${applicationEnv.loUrl}/ssoservicesv2/loginKeycloakUser`,
        options
    );

    if (response && response.ok) {
        return response.json();
    }

    throw new Error('request_error');
};

export {
    initAuthentication,
    initLogout,
    initAuthenticationFromStorage,
    refreshAccessToken,
    loginLuminaOnline,
    logoutLuminaOnline,
};
