import { useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import logError from '../../services/common';

const ApplicationErrorAlert = ({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: any }) => {
    const addBodyClass = (className: string) => document.body.classList.add(className);
    const removeBodyClass = (className: string) => document.body.classList.remove(className);

    useEffect(() => {
        addBodyClass('gray-bg');

        return () => {
            removeBodyClass('gray-bg');
        };
    }, []);

    // Send error to SSO backend
    useEffect(() => {
        try {
            logError(error);
        } catch (loggingError) {
            console.error((loggingError as Error)?.message);
            console.error('Error not logged');
        }
    }, [error]);

    return (
        <div className="application-crash-page">
            <Alert variant="danger">
                <Alert.Heading>
                    <FormattedMessage id="APPLICATION_ERROR_ALERT.HEADING" defaultMessage="Error" />
                </Alert.Heading>
                <FormattedMessage id="APPLICATION_ERROR_ALERT.MESSAGE_1" defaultMessage="Something went wrong, please refresh the page or try again later" />
                <br />
                <FormattedMessage id="APPLICATION_ERROR_ALERT.MESSAGE_2" defaultMessage="If problem persist, please contact support at support@luminalearning.com" />
                <br />
                <br />
                <Button variant="lumina" onClick={resetErrorBoundary}>
                    <FormattedMessage id="APPLICATION_ERROR_ALERT.TRY_AGAIN_BUTTON" defaultMessage="Try Again" />
                </Button>
            </Alert>
        </div>
    );
};

export default ApplicationErrorAlert;