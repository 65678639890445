import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCloudDownloadAlt,
    faCubes,
    faIdCard,
    faLock,
    faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare as farCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Sidebar = () => (
    <ul>
        <NavLink to='/portal/dashboard' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
            <li>
                <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                    <FontAwesomeIcon icon={farCheckSquare} className='icon' />
                </span>
                <FormattedMessage id="SIDEBAR.TASKS_TO_COMPLETE" defaultMessage="Tasks to Complete"/>
            </li>
        </NavLink>
        <NavLink to='/portal/portraits' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
            <li>
                <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                    <FontAwesomeIcon icon={faCloudDownloadAlt} className='icon' />
                </span>
                <FormattedMessage id="SIDEBAR.PORTRAIT_DOWNLOADS" defaultMessage="Portrait Downloads"/>
            </li>
        </NavLink>
        <NavLink to='/portal/services' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
            <li>
                <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                    <FontAwesomeIcon icon={faCubes} className='icon' />
                </span>
                <FormattedMessage id="SIDEBAR.LUMINA_SERVICES" defaultMessage="Lumina Services"/>
            </li>
        </NavLink>
        <NavLink to='/portal/profile' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
            <li>
                <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                    <FontAwesomeIcon icon={faIdCard} className='icon' />
                </span>
                <FormattedMessage id="SIDEBAR.PERSONAL_DETAILS" defaultMessage="Personal Details"/>
            </li>
        </NavLink>
        <NavLink to='/portal/security' className={({ isActive }) => isActive ? 'sidebar-active' : '' }>
            <li>
                <span className='fa-layers fa-fw'>
                    <FontAwesomeIcon icon={faCircle} size='2x' className='circle' />
                    <FontAwesomeIcon icon={faLock} className='icon' />
                </span>
                <FormattedMessage id="SIDEBAR.ACCOUNT_SECURITY" defaultMessage="Account Security"/>
            </li>
        </NavLink>
    </ul>
);

export default Sidebar;