import { gql } from '@apollo/client';

const GET_PAGE = gql`
    query GetPage {
        currentPage @client
    }
`;

const GET_TOKEN = gql`
    query GetToken {
        token @client
    }
`;

export {
    GET_PAGE,
    GET_TOKEN,
};