import { gql } from '@apollo/client';

const REGISTER_KEYCLOAK_USER = gql`
    mutation RegisterUser($username: String!, $email: String!, $password: String!, $projectId: Int) {
        registerUser(username: $username, email: $email, password: $password, projectId: $projectId) {
            ... on Keycloak {
                userId username email
            }
            ... on ErrorResponse {
                error { code message }
            }
        }
    }
`;

const UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE = gql`
    mutation UpdatePreferredLanguage($preferredLanguage: String!) {
        updatePreferredLanguage(preferredLanguage: $preferredLanguage)
    }
`;

const VERIFY_KEYCLOAK_EMAIL = gql`
    mutation VerifyKeycloakEmail($keycloakId: Int!, $verificationToken: String!) {
        verifyKeycloakEmail(keycloakId: $keycloakId, verificationToken: $verificationToken)
    }
`;

const RESET_KEYCLOAK_PASSWORD = gql`
    mutation ResetKeycloakPassword($keycloakUserId: String!, $password: String!) {
        resetKeycloakPassword(keycloakUserId: $keycloakUserId, password: $password)
    }
`;

const GET_COMMUNICATION_PREFERENCES = gql`
    query CommunicationPreferences {
        communicationPreferences {
            participant { learning research productsAndServices }
            practitioner { luminaEventsProductsAndSolutions partnerEventsProductsAndSolutions }
        }
    }
`;

const UPDATE_COMMUNICATION_PREFERENCES = gql`
    mutation UpdateCommunicationPreferences($communicationPreferences: KeycloakUserCommunicationPreferencesInput!) {
        updateCommunicationPreferences(communicationPreferences: $communicationPreferences) {
            participant { learning research productsAndServices }
            practitioner { luminaEventsProductsAndSolutions partnerEventsProductsAndSolutions }
        }
    }
`;

const REQUEST_KEYCLOAK_USER_EMAIL_CHANGE = gql`
    mutation UpdateKeycloakEmail($currentPassword: String!, $email: String!) {
        updateKeycloakEmail(currentPassword: $currentPassword, email: $email)
    }
`;

const REQUEST_LUMINA_ONLINE_PROFILE_DETAILS = gql`
    query KeycloakLuminaProfileDetails($keycloakUserId: String!) {
        keycloakLuminaProfileDetails(keycloakUserId: $keycloakUserId) {
            participants {
                id
                firstName
                lastName
                email
                guid
                gender
                genderPronoun
                description
                additionalName { languageId languageName firstName lastName }
                joinedProjectsSelf { projectId }
                joinedProjectsRater { contextGuid }
            }
            practitioners { id firstName lastName email description }
            partners { id firstName lastName email description }
            staff { id firstName lastName email description }
        }
    }
`;

const UPDATE_LUMINA_PROFILE_EMAIL = gql`
    mutation UpdateLuminaProfileEmail($luminaUserType: String!, $luminaUserId: Int!, $email: String!) {
        updateLuminaProfileEmail(luminaUserType: $luminaUserType, luminaUserId: $luminaUserId, email: $email)
    }
`;

const UPDATE_LUMINA_PROFILE_MAPPING_DESCRIPTION = gql`
    mutation UpdateLuminaProfileDescription($luminaUserType: String!, $luminaUserId: Int!, $description: String!) {
        updateLuminaProfileDescription(
            luminaUserType: $luminaUserType,
            luminaUserId: $luminaUserId,
            description: $description
        )
    }
`;

const CHECK_USERNAME_FOR_LOGIN = gql`
    query CheckUsernameOrEmail($usernameOrEmail: String!) {
        checkUsernameOrEmail(usernameOrEmail: $usernameOrEmail)
    }
`;

const GET_KEYCLOAK_EMAIL = gql`
    query KeycloakEmail {
        keycloakEmail
    }
`;

const KEYCLOAK_PROFILE_DETAILS = gql`
    query KeycloakProfileDetails($keycloakUserId: String!) {
        luminaKeycloakAccount(keycloakUserId: $keycloakUserId) {
            keycloakUserId
            slug
            username
            email
            appMetadata
            isEmailVerified
            isEnabled
            language
            gender
            signedUpAt { formatted timezoneOffset timezone }
            updatedAt { formatted timezoneOffset timezone }
            lastLogin { formatted timezoneOffset timezone }
        }
        keycloakLuminaProfileDetails(keycloakUserId: $keycloakUserId) {
            participants { id firstName lastName email }
            practitioners { id firstName lastName email }
            partners { id firstName lastName email }
            staff { id firstName lastName email }
        }
    }
`;

const UNBLOCK_KEYCLOAK_USER = gql`
    mutation UnblockKeycloakUser($keycloakUserId: String!) {
        unblockKeycloakUser(keycloakUserId: $keycloakUserId)
    }
`;

const UNMAP_LUMINA_PROFILE = gql`
    mutation UnmapProfile(
        $luminaAccountType: String!,
        $keycloakUserId: String,
        $profileType: String!,
        $profileId: Int!
    ) {
        unmapProfile(
            luminaAccountType: $luminaAccountType,
            keycloakUserId: $keycloakUserId,
            profileType: $profileType,
            profileId: $profileId
        )
    }
`;

const REQUEST_FORGOT_USERNAME_EMAIL = gql`
    mutation SendForgotUsernameEmail($emailAddress: String!) {
        sendForgotUsernameEmail(emailAddress: $emailAddress)
    }
`;

const MAP_LUMINA_PROFILE_TO_KEYCLOAK = gql`
    mutation MapLuminaProfile(
        $targetKeycloakUserId: String!,
        $targetLuminaProfileEmail: String!,
        $targetLuminaProfileType: String!
    ) {
        mapLuminaProfile(
            targetKeycloakUserId: $targetKeycloakUserId,
            targetLuminaProfileEmail: $targetLuminaProfileEmail,
            targetLuminaProfileType: $targetLuminaProfileType
        )
    }
`;

const REQUEST_RESET_KEYCLOAK_PASSWORD = gql`
    mutation StaffSendKeycloakResetPasswordEmail($keycloakUserId: String!) {
        staffSendKeycloakResetPasswordEmail(keycloakUserId: $keycloakUserId)
    }
`;

export {
    REGISTER_KEYCLOAK_USER,
    UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE,
    VERIFY_KEYCLOAK_EMAIL,
    RESET_KEYCLOAK_PASSWORD,
    GET_COMMUNICATION_PREFERENCES,
    UPDATE_COMMUNICATION_PREFERENCES,
    REQUEST_KEYCLOAK_USER_EMAIL_CHANGE,
    REQUEST_LUMINA_ONLINE_PROFILE_DETAILS,
    UPDATE_LUMINA_PROFILE_EMAIL,
    UPDATE_LUMINA_PROFILE_MAPPING_DESCRIPTION,
    CHECK_USERNAME_FOR_LOGIN,
    GET_KEYCLOAK_EMAIL,
    KEYCLOAK_PROFILE_DETAILS,
    UNBLOCK_KEYCLOAK_USER,
    UNMAP_LUMINA_PROFILE,
    REQUEST_FORGOT_USERNAME_EMAIL,
    MAP_LUMINA_PROFILE_TO_KEYCLOAK,
    REQUEST_RESET_KEYCLOAK_PASSWORD,
};
