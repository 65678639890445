import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import applicationEnv from '../applicationEnv';
import { locales, selectValidLocale } from '../services/intl';
import { IS_LOGGED_IN } from './authQuery';

const currentPage = makeVar('');
const currentLanguage = makeVar('');
const decodedToken = makeVar({});

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                currentPage: {
                    read() {
                        return currentPage();
                    },
                },
                appLocale: {
                    read() {
                        return currentLanguage();
                    },
                },
                token: {
                    read() {
                        return decodedToken();
                    },
                },
            },
        },
    },
});

const initCache = () => {
    cache.writeQuery({
        query: IS_LOGGED_IN,
        data: {
            isLoggedIn: !!localStorage.getItem('LUMINA_ACCESS_TOKEN'),
        },
    });

    return cache;
};

const initLocale = () => {
    // Check initial language from local storage or browser language, otherwise revert back to english uk
    const localeFromStorage = localStorage.getItem('locale');
    let initialLocale = localeFromStorage || navigator.language;
    if (!initialLocale || !(initialLocale in locales)) {
        initialLocale = applicationEnv.defaultLocale;
    }

    // Check if locale is supported
    initialLocale = selectValidLocale(initialLocale);
    localStorage.setItem('locale', initialLocale);

    currentLanguage(initialLocale);
};

const initClient = () => {
    const httpLink = createHttpLink({ uri: applicationEnv.graphqlGatewayUrl });

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('LUMINA_ACCESS_TOKEN');

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: initCache(),
        credentials: 'same-origin',
    });

    initLocale();

    return client;
};

export {
    currentPage, currentLanguage, decodedToken, cache, initClient,
};
