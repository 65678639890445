import { gql } from '@apollo/client';

const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`;

const AUTH_LOGIN = gql`
    mutation AuthLogin(
        $username: String!
        $password: String!
        $currentLanguage: String
    ) {
        authLogin(
            username: $username
            password: $password
            currentLanguage: $currentLanguage
        ) {
            ... on AuthResult {
                keycloakUserId accessToken refreshToken
            }
            ... on ErrorResponse {
                error { code message }
            }
        }
    }
`;

const AUTH_LOGOUT = gql`
    mutation AuthLogout {
        authLogout
    }
`;

const AUTH_REFRESH = gql`
    mutation AuthRefresh($refreshToken: String!) {
        authRefresh(refreshToken: $refreshToken) {
            ... on AuthResult {
                keycloakUserId accessToken refreshToken
            }
            ... on ErrorResponse {
                error { code message }
            }
        }
    }
`;

const RESEND_VERIFICATION_EMAIL = gql`
    mutation ResendKeycloakVerificationEmail($keycloakUserId: String!) {
        resendKeycloakVerificationEmail(keycloakUserId: $keycloakUserId)
    }
`;

const SEND_RESET_PASSWORD_EMAIL = gql`
    mutation SendResetPasswordEmail($username: String, $email: String) {
        sendResetPasswordEmail(username: $username, email: $email)
    }
`;

const VALIDATE_RESET_PASSWORD_TOKEN = gql`
    query PasswordResetToken($keycloakId: Int!, $token: String!) {
        passwordResetToken(keycloakId: $keycloakId, token: $token) {
            ... on Keycloak {
                userId username email
            }
            ... on ErrorResponse {
                error { code message }
            }
        }
    }
`;

const UPDATE_PASSWORD = gql`
    mutation UpdateKeycloakPassword($currentPassword: String!, $password: String!) {
        updateKeycloakPassword(currentPassword: $currentPassword, password: $password)
    }
`;

const CHECK_PASSWORD = gql`
    query CheckCurrentPassword($password: String!) {
        checkCurrentPassword(password: $password)
    }
`;

const STAFF_UPDATE_USER_PASSWORD = gql`
    mutation UpdateKeycloakPasswordByStaff($username: String!, $newPassword: String!) {
        updateKeycloakPasswordByStaff(username: $username, newPassword: $newPassword)
    }
`;

const UNBLOCK_ACCOUNT = gql`
    mutation UnblockAccount($unblockToken: String!) {
        unblockAccount(unblockToken: $unblockToken) {
            username
            message
        }
    }
`;

export {
    IS_LOGGED_IN,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REFRESH,
    RESEND_VERIFICATION_EMAIL,
    SEND_RESET_PASSWORD_EMAIL,
    VALIDATE_RESET_PASSWORD_TOKEN,
    UPDATE_PASSWORD,
    CHECK_PASSWORD,
    STAFF_UPDATE_USER_PASSWORD,
    UNBLOCK_ACCOUNT,
};
