import {
    faAddressCard, faAngleDown, faCommentDots, faSignInAlt, faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import DetectOutsideClick from '../Common/DetectOutsideClick';
import { GET_PAGE } from '../../graphql/helperQuery';

const ProfileNav = (
    {
        isMobile,
        isLoggedIn,
        isNavigating,
        isProfileDropdownOpen,
        setIsProfileDropdownOpen,
        username,
        email,
        goToProfile,
        goToSupportPage,
        goToLogin,
        logout,
    }: {
        isMobile: boolean;
        isLoggedIn: boolean;
        isNavigating: boolean;
        isProfileDropdownOpen: boolean;
        setIsProfileDropdownOpen: Function;
        username: string;
        email: string;
        goToProfile: Function;
        goToSupportPage: Function;
        goToLogin: Function;
        logout: Function;
    }
) => {
    const { data: page } = useQuery(GET_PAGE);

    return (
        <>
            {isLoggedIn
                && <DetectOutsideClick callback={() => setIsProfileDropdownOpen(false)}>
                    <button className={`logged-in-profile dropdown-toggle ${isProfileDropdownOpen ? 'open' : ''}`}
                        data-toggle="dropdown"
                        aria-expanded={isProfileDropdownOpen}
                        onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
                    >
                        <span>{ username }</span>
                        <FontAwesomeIcon icon={faAngleDown} size="lg" />
                    </button>
                    <div
                        className={
                            // eslint-disable-next-line max-len
                            `${isMobile ? 'mobile-profile-menu' : 'dropdown-menu'} ${isProfileDropdownOpen ? 'open' : ''}`
                        }
                    >
                        <div className="profile">
                            <p className="username">{ username }</p>
                            <span className="email">{ email }</span>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                disabled={isNavigating}
                                onClick={() => goToProfile()}
                            >
                                <FontAwesomeIcon icon={faAddressCard} size="sm"/>{' '}
                                <FormattedMessage id="PROFILE_NAV.MY_PROFILE_NAV" defaultMessage="My Profile" />
                            </Button>
                        </div>
                        <div className="external-links">
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                disabled={isNavigating}
                                onClick={() => goToSupportPage()}
                            >
                                <FontAwesomeIcon icon={faCommentDots} size="sm"/>{' '}
                                <FormattedMessage id="PROFILE_NAV.LUMINA_SUPPORT_NAV" defaultMessage="Contact Support" />
                            </Button>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => logout()}
                                disabled={isNavigating}
                            >
                                <FontAwesomeIcon icon={faSignOutAlt} size="sm"/>{' '}
                                <FormattedMessage id="PROFILE_NAV.LOGOUT_NAV" defaultMessage="Sign Out" />
                            </Button>
                        </div>
                    </div>
                </DetectOutsideClick>}

            { !isLoggedIn && page.currentPage !== 'project-registration'
                && <div className="logged-out-profile">
                    <button onClick={() => goToLogin()}>
                        <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                        <span><FormattedMessage id="PROFILE_NAV.LOGIN_NAV" defaultMessage="Sign In" /></span>
                    </button>
                </div> }
        </>
    );
};

export default ProfileNav;