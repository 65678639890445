import jwt_decode from 'jwt-decode';
import applicationEnv from '../applicationEnv';

const decodeJwt = (accessToken: string) => jwt_decode(accessToken) as any;

const isHasStaffProfile = (decodedToken: any) => 'app_metadata' in decodedToken
    && decodedToken.app_metadata
    && 'lumina_online' in decodedToken.app_metadata
    && 'profiles' in decodedToken.app_metadata.lumina_online
    && 'staff' in decodedToken.app_metadata.lumina_online.profiles
    && decodedToken.app_metadata.lumina_online.profiles.staff.length > 0;

const isHasPartnerProfile = (decodedToken: any) => 'app_metadata' in decodedToken
    && decodedToken.app_metadata
    && 'lumina_online' in decodedToken.app_metadata
    && 'profiles' in decodedToken.app_metadata.lumina_online
    && 'partners' in decodedToken.app_metadata.lumina_online.profiles
    && decodedToken.app_metadata.lumina_online.profiles.partners.length > 0;

const isTokenExpired = (accessToken: string) => decodeJwt(accessToken).exp < Math.round(new Date().getTime() / 1000);
const isTokenIssValid = (accessToken: string) => (
    decodeJwt(accessToken).iss === `${applicationEnv.keycloakServerUrl}/auth/realms/${applicationEnv.keycloakRealm}`
);

export {
    decodeJwt,
    isHasStaffProfile,
    isHasPartnerProfile,
    isTokenExpired,
    isTokenIssValid,
};