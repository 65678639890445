import { useEffect, useRef } from 'react';

// Hooks specifically to replace ComponentDidUpdate event
const useDidUpdateEffect = (callback: Function, deps: any[]) => {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            // Any subsequent update will run the callback function
            callback();
        } else {
            // On initial render, this will be false, set this to true
            didMountRef.current = true;
        }
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdateEffect;