import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClick = (
    ref: React.MutableRefObject<any>,
    callback: Function
) => {
    useEffect(() => {
        const currentRef = ref.current;
        const handleClickOutside = (e: MouseEvent) => {
            if (currentRef && !currentRef.contains(e.target)) {
                callback();
            }
        };

        // Bind the event listener
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            // componentWillUnmount()
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ref, callback]);
};

export default useOutsideClick;