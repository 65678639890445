import { useState, useEffect, useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { RESEND_VERIFICATION_EMAIL } from '../../graphql/authQuery';
import SupportMailto from '../Common/SupportMailto';
import AdminSidebar from './AdminSidebar';
import Sidebar from './Sidebar';
import { decodeJwt } from '../../helpers/checkJwt';

const MainSidebar = ({ isAdminPage, isPartnerPage }: { isAdminPage?: boolean; isPartnerPage?: boolean; }) => {
    const accessToken = localStorage.getItem('LUMINA_ACCESS_TOKEN');
    const [isCssLoaded, setIsCssLoaded] = useState(false);
    const [isSendingVerificationEmail, setIsSendingVerificationEmail] = useState(false);
    const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(true);
    const [isVerificationEmailSendingError, setIsVerificationEmailSendingError] = useState(false);

    const resendVerificationEmailError = () => {
        setIsVerificationEmailSendingError(true);
        setIsVerificationEmailSent(false);
        setIsSendingVerificationEmail(false);
    };

    const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL, {
        onCompleted: useCallback((response: { resendKeycloakVerificationEmail?: boolean }) => {
            if (
                response
                && 'resendKeycloakVerificationEmail' in response
                && response.resendKeycloakVerificationEmail
            ) {
                setIsVerificationEmailSent(true);
                setIsSendingVerificationEmail(false);
            } else {
                resendVerificationEmailError();
            }
        }, []),
        onError: useCallback((errorResponse: unknown) => {
            console.error(errorResponse);
            resendVerificationEmailError();
        }, []),
    });

    const sendVerificationEmail = () => {
        if (accessToken) {
            const decoded = decodeJwt(accessToken);
            if (decoded) {
                setIsSendingVerificationEmail(true);
                resendVerificationEmail({ variables: { keycloakUserId: decoded.sub } });
            }
        }
    };

    useEffect(() => {
        let isEmailVerifiedfromToken = false;
        if (accessToken) {
            const decoded = decodeJwt(accessToken);
            if (decoded) {
                isEmailVerifiedfromToken = decoded.email_verified;
            }
        }
        setIsEmailVerified(isEmailVerifiedfromToken);

        if (!isCssLoaded) {
            // eslint-disable-next-line global-require
            require('./Sidebar.scss');

            setIsCssLoaded(true);
        }
    }, [isCssLoaded, accessToken]);

    return (
        <div className='main-sidebar'>
            <div>
                { !isEmailVerified
                    && <Alert variant='danger' className='email-verification-alert'>
                        <p style={{ marginBottom: '16px' }}>
                            <FormattedMessage
                                id="MAIN_SIDEBAR.EMAIL_NOT_VERIFIED"
                                defaultMessage="Your email has not been verified, please click the link we emailed to you."
                            />
                        </p>

                        { isVerificationEmailSent
                            ? <p>
                                <FormattedMessage
                                    id="MAIN_SIDEBAR.VERIFICATION_EMAIL_SENT"
                                    defaultMessage="We have sent email verification to your email, please check your inbox or junk folder"
                                />
                            </p>
                            : (isVerificationEmailSendingError
                                ? <p>
                                    <FormattedMessage
                                        id="MAIN_SIDEBAR.VERIFICATION_EMAIL_ERROR"
                                        defaultMessage="Something went wrong when trying to send verification email, please try again later or contact support for more information"
                                    />{' '}
                                    <SupportMailto />
                                </p>
                                : <Button
                                    size='sm'
                                    variant='lumina'
                                    disabled={isSendingVerificationEmail}
                                    onClick={() => sendVerificationEmail()}
                                >
                                    <FormattedMessage
                                        id="MAIN_SIDEBAR.VERIFICATION_EMAIL_RESEND"
                                        defaultMessage="Resend Verification Email"
                                    />
                                </Button>
                            )}
                    </Alert> }
            </div>
            <nav>
                { isAdminPage || isPartnerPage
                    ? <AdminSidebar isStaff={typeof isAdminPage !== 'undefined' && isAdminPage} />
                    : <Sidebar /> }
            </nav>
        </div>
    );
};

export default MainSidebar;
