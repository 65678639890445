import { faChevronRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import applicationEnv from '../../applicationEnv';

const KnowledgeCenterNav = ({ isMobile }: { isMobile: boolean; }) => (
    <Nav.Link href={applicationEnv.luminaSupportUrl} className={`${isMobile ? 'mobile-' : ''}support-nav`}>
        <FontAwesomeIcon icon={faQuestionCircle} size="2x" className="support-icon" />
        <div className="support-text">
            <span><FormattedMessage id="KNOWLEDGE_CENTER.SUPPORT_HELP" defaultMessage="Need some help?" /></span>
            <span>
                <FormattedMessage id="KNOWLEDGE_CENTER.SUPPORT_HELP_NAV" defaultMessage="View FAQs, tutorials and support" />
                <FontAwesomeIcon icon={faChevronRight} size="lg" />
            </span>
        </div>
    </Nav.Link>
);

export default KnowledgeCenterNav;