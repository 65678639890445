import applicationEnv from '../applicationEnv';

const logError = async (error: Error) => {
    const options: any = {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ error: JSON.stringify(error, Object.getOwnPropertyNames(error)) }),
    };

    await fetch(`${applicationEnv.ssoUrl}/api/send-error`, options);
};

export default logError;