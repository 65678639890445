import { faAngleDown, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { locales } from '../../services/intl';

const LanguageSelection = (
    {
        isMobileMenu,
        isDropdownOpen,
        isLanguageDropdownEnabled,
        setIsDropdownOpen,
        currentLanguage,
        languageChangeCallback,
    }: {
        isMobileMenu: boolean;
        isDropdownOpen: boolean;
        isLanguageDropdownEnabled: boolean;
        setIsDropdownOpen: Function;
        currentLanguage: { localName?: string, englishName: string };
        languageChangeCallback: Function;
    }
) => {
    const validLocales: any = locales;
    return (
        <>
            <button
                className={`dropdown-toggle ${isDropdownOpen ? 'open' : ''}`}
                data-toggle="dropdown"
                aria-expanded={isDropdownOpen}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <FontAwesomeIcon icon={faLanguage} size="2x" className="language-icon" />
                <div className="language-text">
                    <span><FormattedMessage id="LANGUAGE_SELECTION.SELECT_DROPDOWN" defaultMessage="Choose your language" />:</span>
                    <span>
                        { 'localName' in currentLanguage
                            ? currentLanguage.localName
                            : currentLanguage.englishName }
                        <FontAwesomeIcon icon={faAngleDown} size="lg" />
                    </span>
                </div>
            </button>
            <ul
                className={
                    `${isMobileMenu ? 'mobile-language-menu' : 'dropdown-menu'} ${isDropdownOpen ? 'open' : ''}`
                }
            >
                { Object.keys(validLocales).map(
                    (localeKey: any, i: number) => (
                        <li key={i}>
                            <button
                                onClick={() => languageChangeCallback(localeKey)}
                                disabled={!isLanguageDropdownEnabled}
                            >
                                { 'localName' in validLocales[localeKey]
                                    ? validLocales[localeKey].localName
                                    : validLocales[localeKey].englishName }
                            </button>
                        </li>
                    )
                )}
            </ul>
        </>
    );
};

export default LanguageSelection;