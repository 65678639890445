import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import { initClient } from './graphql/client';

const run = async () => {
    const apolloClient: ApolloClient<NormalizedCacheObject> = initClient();
    const root = createRoot(document.getElementById('root')!);

    root.render(
        <StrictMode>
            <ApolloProvider client={apolloClient}>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </ApolloProvider>
        </StrictMode>
    );
};

run();
